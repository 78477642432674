import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Landing.module.css';

// Typing Effect
function TypingEffect({ text, onComplete }) {
  const [displayedText, setDisplayedText] = useState('');
  const [isBlinking, setIsBlinking] = useState(true);
  const onCompleteRef = useRef(onComplete);

  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setDisplayedText((prev) => prev + text.charAt(index));
      index++;
      if (index === text.length) {
        clearInterval(intervalId);
        setIsBlinking(false);
        if (onCompleteRef.current) {
          onCompleteRef.current();
        }
      }
    }, 100); // Adjust typing speed

    return () => clearInterval(intervalId);
  }, [text]); // Only re-run the effect if text changes

  return (
    <span className="typing-effect">
      {displayedText}
      {isBlinking && <span className="cursor">|</span>}
    </span>
  );
}




function LandingPage() {
  let navigate = useNavigate();
  const [isTitleDone, setIsTitleDone] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleTitleComplete = () => {
    setIsTitleDone(true);
  };

  const handleContentComplete = () => {
    setShowButton(true); // Show the button after content is displayed
  };

  const navigateToChat = () => {
    navigate('/chat');
  };

  return (
    <div className={styles['main-container']}>
      <header>
        <h1 className={styles.title}>
          <TypingEffect text="Welcome to Waveknit Musicology Chat" onComplete={handleTitleComplete} />
        </h1>
        {/* Navigation, if necessary */}
      </header>
      <p className={styles.content}>
        {isTitleDone && (
          <TypingEffect text="Discover a new way to interact with AI in musicology." onComplete={handleContentComplete}/>
        )}
      </p>
      {/* Additional content */}
      {showButton && (
        <button className={`${styles['start-chat-button']} ${styles.fadeIn}`} onClick={navigateToChat}>
          Start Chatting
        </button>
      )}
    </div>
  );
}

export default LandingPage;
