import "./Chat.css";
import React, { useState } from "react";

export default function App() {
  const [inputMessage, setInputMessage] = useState("");
  //   const [outputMessage, setOutputMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) { // Check if Enter key is pressed without the Shift key
      e.preventDefault(); // Prevent the default action to avoid a line break in the input
      handleSendMessage(); // Call the function to handle the message submission
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim()) {
      // Add the user's message to the messages list
      const newMessages = [
        ...messages,
        { sender: "user", type: "sent", text: inputMessage },
        { sender: "assistant", type: "received", text: "", loading: true } // Add a loading message for the bot
      ];
      setMessages(newMessages);
      setInputMessage(""); // Clear the input field

      // forge messgage system to chat-gpt compatiable object 
      const newLLMMessages = newMessages.map(newlMessage => {
        // Create a new object based on the original one
        const llmMessage = {
          role: newlMessage.sender, // Copy sender from the original
          content: newlMessage.text,     // Copy type from the original
        };
        return llmMessage;
      });

      try {
        // Call the cloud function and wait for the response
        const responseData = await callCloudFunction({ input: newLLMMessages });

        // Update the messages list with the response from the cloud function
        setMessages(prevMessages => {
          // Copy the current messages
          const updatedMessages = [...prevMessages];
          // Find the last bot message (which should be the loading message)
          const lastBotMessageIndex = updatedMessages.length - 1;
          // Update the last bot message with the actual response
          updatedMessages[lastBotMessageIndex] = {
            sender: "assistant",
            type: "received",
            text: responseData.response,
            loading: false
          };

          return updatedMessages;
        });
      } catch (error) {
        console.error('Error fetching response:', error);
        // Optionally handle the error, e.g., display an error message
        setMessages(prevMessages => {
          // Copy the current messages
          const updatedMessages = [...prevMessages];
          // Find the last bot message (which should be the loading message)
          const lastBotMessageIndex = updatedMessages.length - 1;
          // Update the last bot message with the actual response
          updatedMessages[lastBotMessageIndex] = {
            sender: "assistant",
            type: "received",
            text: "Something wrong with the conversation, please reload page.",
            loading: false
          };

          return updatedMessages;
        });
      }
    }
  };

  const callCloudFunction = async (data) => {
    try {
      const response = await fetch('https://us-central1-waveknit-musicology.cloudfunctions.net/gpt-chat-musicology', {
        method: 'POST', // or 'GET', depending on your Cloud Function
        headers: {
          'Content-Type': 'application/json',
          // Add other headers as needed
        },
        body: JSON.stringify(data), // send data as JSON
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error calling cloud function:', error);
      throw error;
    }
  };


  return (
    <div className="main-container">
      <div className="header">
        <img src="logo.png" alt="Waveknit Musicology Logo" className="logo" />
        <h1 className="title">Waveknit Musicology</h1>
      </div>
      <div className="chat-box">
        <div className="messages">
          {/* Some messages */}
          <div className="message received">
            <img
              src="avataaars1.png"
              alt="Receiver Avatar"
              className="avatar"
            />
            <span className="text">Hello! How can I help you today?</span>
          </div>
          {messages.map((message, index) => (
            <div key={index}>
              {message.sender === "user" ? (
                <div className="message sent">
                  <span className="text">{message.text}</span>
                  <img src="avataaars.png" alt="Sender Avatar" className="avatar" />
                </div>
              ) : (
                <div className="message received">
                  <img src="avataaars1.png" alt="Receiver Avatar" className="avatar" />
                  {message.loading ? (
                    <div className="loading-animation">
                      {/* Your SVG loading animation here */}
                      <img src="musical-note.svg" className="loading-animation" alt="Loading" />
                    </div>
                  ) : (
                    <span className="text">{message.text}</span>
                  )}
                </div>
              )}
            </div>
          ))}

          {/* More messages */}
        </div>
        <div className="input-container">
          <input
            type="text"
            className="message-input"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyPress} // Add the onKeyDown event handler
          />
          <button className="send-button" onClick={handleSendMessage}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
